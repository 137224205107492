@import './src/styles';

%header-settings {

  .settings-header {
    @extend %heading-1;
    margin-top: 0;
    margin-bottom: 50px;
  }

  .settings-wrapper {
    padding: 0 50px 0 50px;
    width: 100%;
    min-height: 60vh;
    display: flex;
    flex-direction: column;
    gap: 50px;

    .settings-container-left {
      display: flex;
      width: 96%;
      max-width: 630px;
      justify-content: flex-start;
    }

    .settings-icon {
      padding: 20px 5px;
      border-bottom: 1px solid $light-neutral;
      width: 100%;
      white-space: nowrap;
      &:active, &.active {
        border-bottom: 1px solid $active-default;
      }
    }

    .settings-container-right {
      width: 100%;
      display: flex;
      justify-content: center;
      box-shadow: 0 4px 38px rgba(143, 39, 199, 0.05);
      border-radius: 6px;
    }

    .settings-input-wrapper {
      margin-top: 30px;
      margin-bottom: 40px;
      max-width: 372px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;

      .settings-input {
        max-width: 350px;
        min-width: unset;
        width: 100%;
        margin-bottom: 19px;
      }
    }
  }
}

.settings {
  @extend %header-settings;

  .settings-alert {
    margin-bottom: 34px;
  }
}

@include mobile {
  .settings {

    .settings-wrapper {
      padding: 0 20px;
      width: 100vw;
      box-shadow: none;
    }

    .settings-header {
      text-align: center;
      margin-bottom: 39px;
    }

    .settings-wrapper {
      padding: 0 20px;
      width: 100vw;
      box-shadow: none;

      .settings-input-wrapper {
        margin-bottom: 0;
      }

      .settings-container-left {
        display: flex;
        justify-content: flex-start;
        overflow-x: auto;

        .settings-icon {
          width: 100%;
          padding: 20px 20px 20px 0;
        }
      }

      .settings-container-right {
        background-color: $white;
        flex-direction: column;
        padding: 20px;
      }
    }
  }
}
