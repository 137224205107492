@import './src/styles';

.transactions-container {
  position: relative;

  .transactions-header-wrapper {
    width: 100%;
    margin: 50px 0 38px 0;
    display: flex;
    border-bottom: transparent;
  }
}

.transaction-link {
  position: absolute;
  top: 28px;
  right: 42px;
  @extend %heading-2;
}

.transaction-table-wrapper {
  position: relative;
  width: 100%;
}

.transactions-wrapper {
  min-width: 600px;
  width: 100%;

  .arrow-icon {
    cursor: pointer;
    margin-left: 10px;

    .up, .down {
      color: $black;
    }

    &.ASC {
      .up {
        color: $black;
      }
      .down {
        color: $light-neutral;
      }
    }

    &.DESC {
      .up {
        color: $light-neutral;
      }
      .down {
        color: $black;
      }
    }

    &:hover {
      .up, .down {
        color: $active-hover;
      }
    }
  }

  thead {
    border-bottom: 10px solid transparent;
  }

  tr {
    th {
      @extend %body;
      color: $dark-neutral;
      font-weight: normal;
      padding-right: 10px;

      &.total {
        text-align: center;
      }
    }

    td {
      @extend %caption;
      vertical-align: top;
      padding-right: 10px;
      padding-top: 10px;
    }

    td:first-child,th:first-child {
      padding-left: 31px;
    }
    td:last-child,th:last-child {
      padding-right: 31px;
    }

    .transaction-date, .transaction-purpose {
      color: $neutral;
      min-width: 130px;
    }

    .transaction-from-to {
      color: $header;
    }

    .transaction-amount-commission {
      text-align: left;
      color: $error;
      min-width: 200px;
      &.INCOMING, &.RETURN  {
        color: $hit;
      }

      .amount {
        @extend %body;
        color: currentColor;
      }

      .commission-total {
        margin-top: 5px;
        @extend %annotation;
        color: $dark;
      }
    }

    .transaction-exclamation {
      padding-left: 20px;
    }
  }
}

.transaction-item {
  height: 80px;
  border-bottom: 1px solid $light-neutral;
}

.empty-transactions-container {
  padding-bottom: 0;
}

.empty-transactions {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  .empty-transactions-wrapper {
    max-width: 380px;
    margin-bottom: 1rem;
    margin-left: 2rem;
  }

  .empty-transactions-header {
    @extend %heading-1;
    margin-bottom: 20px;
  }

  .empty-transactions-text {
    @extend %body;
    text-align: left;
  }
}

.transaction-filter-wrapper {
  width: 100%;
  padding: 0 31px;
  margin-bottom: 50px;
}
.tabs-wrapper {
  width: 100%;
  display: flex;

  .filter-tab {
    all: unset;
    @extend %body;
    color: $neutral;
    cursor: pointer;
    height: 60px;
    flex: 1;
    text-align: center;
    border-bottom: 1px solid $light-neutral;

    &:hover {
      color: $active-hover;
      border-bottom: 1px solid $active-hover;
    }

    &:active, &.active  {
      color: $active-default;
      border-bottom: 1px solid $active-default;
    }
  }
}

.filter-wrapper {

  .filter-wrapper-line {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 13px;

    .search {
      flex: 4;
      min-width: 190px;
    }

    .wallet {
      flex: 2;
      .input {
        width: 100%;
        min-width: 300px;
      }
    }

    .transaction-type, .currency, .status {
      flex: 1;
      .input {
        width: 100%;
        min-width: 190px;
      }
    }

    .date {
      flex: 1;
      min-width: 235px;
    }
  }
}

.transaction-mobile-wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 20px;
}

.transaction-item-mobile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  gap: 10px;
  width: 100%;
  background-color: $white;
  box-shadow: 0 4px 38px rgba(143, 39, 199, 0.05);
  border-radius: 8px;
  @extend %caption;

  .icon {
    margin-right: 10px;
    color: $light-neutral;
  }

  .item-mobile-date {
    padding-right: 5px;
  }

  .item-mobile-status, .item-mobile-from-to, .item-mobile-date {
    display: flex;
    align-items: center;
    color: $header;
  }

  .item-mobile-top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .item-mobile-from-to {
    .icon {
      margin-top: -18px;
      width: 20px;
      height: 15px;
    }
  }

  .item-mobile-amount {
    width: 100%;
    display: flex;
    align-items: center;
    .icon {
      width: 18px;
    }
    .item-amount-text {
      margin-right: 20px;
      color: $header
    }
    .exclamation-wrapper {
      margin-left: auto;
    }
  }
}

.filter-mobile-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .filters-close-icon {
    @extend %close-icon;
    color: $white;
    .background {
      color: $neutral;
    }
  }
}

@include mobile {
  .empty-transactions{
    .empty-transactions-wrapper {
      margin-left: 0;
      text-align: center;

      .empty-transactions-text {
        text-align: center;
      }
    }

    .empty-transactions-icon {
      width: 250px;
    }
  }

  .transactions-container {
    .transaction-link {
      width: 100%;
      position: initial;
      margin: 10px 0 20px 0;
      border: 1px solid $dark;
      border-radius: 6px;
      padding: 17px 0;
      color: $dark;
      text-align: center;
    }
  }

  .tabs-wrapper {
    overflow-x: auto;
    min-width: 320px;
    max-width: 360px;
    background-color: $white;

    &::-webkit-scrollbar {
      display: none;
    }
    scrollbar-width: none;

    .filter-tab {
      height: 37px;
      min-width: 100px;
    }
  }

  .transaction-filter-compact {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;

    .filter-compact-wrapper {
      display: flex;
      width: 100%;
      justify-content: space-between;

      .filter-button {
        width: 60px;
      }
    }
  }

  .input-wrapper.search {
    .input-icon {
      right: 20px;
    }
  }

  .transaction-filter-wrapper {
    background-color: $white;
    border-radius: 8px;
    box-shadow: 0 4px 38px rgba(143, 39, 199, 0.05);
    padding: 0 20px;
    width: 100vw;
  }

  .filter-buttons-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;
    .filter-button {
      max-width: 130px;
    }
  }

  .pagination-wrapper {
    margin: 30px 0 0 0;
    padding: 20px;
    width: 100%;
    align-self: center;
    justify-content: space-between;
    background-color: $white;
  }
}
