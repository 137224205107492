@import 'src/uikit/stylesheets/styles';

.onHold {
  color: #FFCF3C;
}

.created .pending, .processing {
  color: $neutral;
}

.completed {
  color: $hit;
}

.rejected, .failed {
  color: $error;
}