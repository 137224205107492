@import './src/styles';
@import './src/components/Settings/Settings';

.profile {
  @extend %header-settings;

  .settings-wrapper {
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding-top: 0;

    .settings-container-right {
      max-width: 600px;
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 30px;

      .profile-info-wrapper.left {
        width: 381px;
      }

      .profile-wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .profile-header {
          @extend %body;
          color: $neutral;
          margin-bottom: 10px;
        }

        .profile-info {
          @extend %heading-2;
        }
      }
    }
  }
}

.profile-info-wrapper {
  margin: 10px;
  min-width: 77px;
}

@include mobile {
  .profile {
    .settings-header {
      text-align: center;
      margin-bottom: 39px;
    }

    .settings-wrapper {
      box-shadow: none;
      background-color: transparent;
      gap: 10px;
      padding: 0 20px ;
      width: 100%;

      .settings-icon {
        width: 100%;
        padding: 19px 5px;
      }

      .settings-container-left {
        width: 96%;
      }

      .settings-container-right {
        max-width: 100%;
        background-color: $white;
        flex-direction: column;
        gap: 10px;

        .profile-wrapper {
          gap: 10px;
        }

        .profile-info-wrapper.left {
          width: 100%;
        }
      }
    }
  }
}