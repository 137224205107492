@import './src/styles';

.scheme-wrapper {
  padding: 19px 30px 30px 30px;
  z-index: 2;
  .scheme-close-icon {
    @extend %close-icon;
  }

  .scheme-header {
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;

    .account {
      @extend %heading-2-sb;
      color: $header;
    }
  }

  .wrapper-balance {
    margin: 30px 0 20px 0;
    padding: 20px;
    border-bottom: 1px solid $light-neutral;

    .scheme-balance {
      @extend %heading-1;
      color: $header;
      margin-right: 5px;
    }

    .scheme-currency {
      @extend  %heading-1;
      color: $neutral;
    }
  }

  .scheme-line {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 38px;
    width: 100%;
    border-bottom: 1px solid $light-neutral;

    .left {
      @extend %caption;
      color: $header;
      width: 90px;
    }

    .center {
      @extend %body;
      color: $neutral;
      width: 70px;
      overflow-wrap: break-word;
      white-space: pre-line;
    }

    svg {
      min-width: 10px;
    }
  }

  .scheme-description {
    max-width: 366px;
    @extend %heading-2;
    color: $header;
    margin-bottom: 30px;
  }
}

.scheme-line-wrapper {
  margin-bottom: 20px;
}

.buttons-wrapper {
  margin-top: 20px;
  display: flex;
  gap: 50px;
}

.pop-up-success, .pop-up-logOut, .pop-up-error {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 70px;
  max-width: 400px;

  .scheme-success-header {
    @extend %heading-1-sb;
    margin-bottom: 20px;
    margin-top: 30px;
    font-weight: 500;
  }

  .scheme-success-message, .scheme-error-message {
    @extend %caption;
    color: $neutral;
    margin-bottom: 30px;
    text-align: center;
  }

  .scheme-error-message {
    max-width: 303px;
    text-align: center;
  }

  .scheme-success-button, .scheme-error-button {
    @extend %heading-2-sb;
    color: $white;
    background-color: $hit;
    height: 60px;
    min-width: 60px;
    border: none;
    border-radius: 6px;
  }

  .scheme-error-button {
    background-color: $error;
  }
}

.pop-up-error {
  padding: 40px 45px;

  .scheme-success-button {
    width: 100px;
  }
}

.pop-up-logOut {
  max-width: 394px;
  padding: 40px 46px;

  .logOut-description-wrapper {
    color: $neutral;
    margin-bottom: 20px;
    @extend %caption;
    text-align: center;
    .logOut-inactivity {
      color: $header;
    }
  }

  .scheme-success-message {
    padding: 0 40px;
    text-align: center;
  }

  .icon-error {
    color: $error;
  }

  .icon-hit {
    color: $hit;
  }
}

.account-details {
  padding: 40px 42px;

  .scheme-description {
    margin: 20px 0 0 0;
    max-width: 450px;
    color: $neutral;
    @extend %caption;
  }

  .scheme-iban {
    color: $neutral;
    margin-bottom: 8px;
  }

  .scheme-line {
    padding: 20px;
    gap: unset;
    margin-top: 0;
    .left {
      width: 160px;
    }

    .center {
      width: 230px;
    }

    &:last-child {
      border-bottom: none;
    }
  }
}

.transaction-info {
  width: 564px;
  height: fit-content;
  .scheme-wrapper {
    width: 100%;
    .scheme-header {
      @extend %heading-1-sb;
      margin-bottom: 33px;
    }
    .scheme-line {
      justify-content: flex-start;
      padding: 20px 0;
      margin: 0;
      .left {
        width: 220px;
        flex: 1;
      }
      .center {
        width: auto;
        flex: 1.5;
        word-break: break-word;
      }
    }
    .buttons-wrapper {
      gap: 10px;
      justify-content: space-between;

      &.transaction-commission {
        justify-content: flex-end;
      }
    }

    .sms-description {
      width: auto;
      margin-bottom: 20px;
    }
  }
}

@include mobile {
  .scheme-line {
    gap: 20px;
  }

  .pop-up-success {
    padding: 40px 50px;
  }

  .account-details {
    padding: 15px 20px;

    .scheme-line {
      flex-wrap: wrap;
    }
  }

  .scheme-wrapper {
    .wrapper-balance {
      margin-top: 17px;
      padding: 10px;
    }
  }

  .transaction-info {
    width: 100%;

    .scheme-wrapper {
      .scheme-line {
        font-size: 14px;
        line-height: 19px;
        padding: 7.5px 0;
        flex-direction: column;
        gap: 10px;

        .left, .center {
          width: 100%;
          font-size: 14px;
          padding-left: 10px;
        }
      }
      .sms-description {
        margin-bottom: 15px;
      }
    }

    .transfer-confirmation {
      .buttons-wrapper {
        flex-direction: column;
        gap: 20px;
      }
    }
  }
}
