@import '../../styles';

.verification {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  background-color: $white;
  border-radius: 6px;
  width: fit-content;

  .bank-icon {
    min-width: 40px;
    margin-bottom: 22px;
  }

  .corporate-icon {
    min-width: 40px;
    margin-bottom: 16px;
  }

  .bank-header {
    @extend  %display-h1;
    margin: 0 0 16px 0;
    &.account {
      margin-bottom: 24px;
    }
  }

  .bank-description {
    @extend %body;
    margin-bottom: 24px;
    max-width: 440px;
  }

  .button {
    width: 185.15px;
  }
}

.corporate-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 30px;
}

.sumsub {
  width: 100%;
}

@include mobile {
  .verification {
    flex-direction: column;
    align-items: start;

    .bank-wrapper {
      display: flex;
      flex-direction: column;
      align-items: start;
    }

    .bank-icon {
      margin: 0 0 20px 0;
    }

    .bank-header {
      margin-bottom: 20px;
      text-align: left;
      width: 280px;
    }

    .bank-description {
      margin: 0 0 20px 0;
      text-align: left;
      width: 280px;
    }
  }
}