@import './src/styles';

.header {
  position: sticky;
  top: 0;
  min-height: 120px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid $light-neutral;
  background-color: $background-color;
  z-index: 2;

  .header-content-wrapper {
    padding: 29px 50px;
    max-width: $desktop-width;
    display: flex;
    margin: 0 auto;
    width: 100%;

    &.active {
      padding: 29px 50px 29px 38px;
      .header-logo {
        transition: all 0.2s ease-in-out;
        margin-right: 35px;
      }
    }
  }

  .header-logo {
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    min-width: 159.21px;
    margin-right: 85px;
  }

  .header-logo-compact {
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    min-width: 35px;
    min-height: 60px;
    margin-right: 64.5px;
  }

  .header-menu-links {
    display: flex;
    flex: 1 1;
    justify-content: space-between;
  }

  .account-dropdown-container {
    margin-left: auto;
  }

  .account-dropdown {
    width: fit-content;
    max-width: 300px;
    text-overflow: ellipsis;
    white-space: nowrap;

    .dropdown-choice-value {
      @extend %heading-2;
    }
  }
  .account-dropdown >:nth-child(2) {
    padding: 0 30px 0 20px;
    background-color: $background-color;
    border-color: $light-neutral;
  }

  .header-menu-button {
    margin-right: 10px;
  }

  .user-panel {
    display: flex;
    align-items: center;
    gap: 30px;
    margin-left: 40px;
  }
}

.open-acc {
  display: flex;
  gap: 16px;
  p {
    @extend %heading-2;
    color: $active-default;
  }
}

//.mobile-navigation {
//  position: absolute;
//  top: 79px;
//  background-color: white;
//  width: 100%;
//}

@include desktop-breakpoint {
  .header {
    .header-logo {
      margin-right: 63px;
    }
    .user-panel {
      gap: 20px;
    }
  }
}

@include mobile {
  .header {
    min-height: 79px;
    background-color: $white;
    box-shadow: 0 4px 38px rgba(143, 39, 199, 0.05);

    .header-content-wrapper, .header-content-wrapper.active {
      padding: 25px 20px;
    }

    .header-menu-links {
      justify-content: flex-end;
    }

    .header-logo {
      margin-right: 44px;
    }
  }
}




