@import 'src/styles';

.exchange-wrapper {
  max-width: 740px;
  padding: 0 20px;

  .exchange-wrapper-alert {
    margin-bottom: 50px;
  }

  .exchange-inputs-wrapper {
    display: flex;
    gap: 30px;
    margin-bottom: 42px;
    flex-wrap: wrap;

  }

  .exchange-inputs-wrapper > :nth-child(n) {
    flex: 1;
    min-width: 330px;
  }

  .exchange-rate {
    .input {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 16px;
      color: $neutral;
    }
  }

  .exchange-rate > :first-child {
    flex: 4;
  }

  .input-sub-text {
    text-align: right;
    margin-right: 10px;
  }
  .input-error {
    text-align: left;
  }
}


@include mobile {
  .exchange-wrapper {
    padding: 20px 0 0 0;

    .exchange-inputs-wrapper {
      & :nth-child(2) { order: 4 }
      & :nth-child(3) { order: 2 }
      & :nth-child(4) { order: 5 }
      & :nth-child(5) { order: 3; margin-bottom: 60px; }
      & :nth-child(6) { order: 6 }
    }

    .exchange-rate {
      height: fit-content;
      gap: 15px;
      flex-direction: column;
    }
  }
}