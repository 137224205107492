// Base colour palette
$black: #000000;
$white: #FFFFFF;
$neutral: #7F8DA0;
$light: #F2F4F8;
$light-neutral: #D2DAE4;
$dark-neutral: #475568;
$active-default: #8F27C7;
$active-hover: #C98DE9;
$active-active: #570086;
$bats-hover: #BB71E3;

$header: #040222;
$dark: #1E293B;
$hit: #20C55D;
$error: #D34F6F;


$bg-primary: #3C81F6;
$bg-soft-primary: rgba(60,129,246,0.5);
$bg-dark: #1E293B;
$bg-soft-dark-2: rgba(30,41,59,0.5);
$bg-secondary: #475568;
$bg-secondary-2: #64758B;
$bg-soft-secondary-2: rgba(100,117,139,0.5);
$bg-danger: #FF4961;
$bg-soft-danger: #FCDBDD;
$danger-text: #8F2E34;
$background-color: #FAFBFD;
$bg-2: #FCF8FF;
$bg-light: #D2DAE4;
$bg-border: #E2E8F0;
$bg-success: #DAF5E7;

//fonts and line height
$font-size-normal: 16px;
$font-size-smaller: 14px;
$font-size-small: 12px;
$line-height-normal: 24px;

%display-h1 {
  font-size: 35px;
  line-height: 48px;
}

%heading-1 {
  color: $black;
  font-size: 25px;
  line-height: 34px;
}

%heading-1-sb {
  font-size: 25px;
  line-height: 34px;
  color: $header;
}

%heading-2 {
  font-size: 18px;
  line-height: 140%;
  color: $header;
}

%heading-2-sb {
  font-size: 18px;
  line-height: 140%;
  font-weight: 500;
}

%body {
  font-size: 16px;
  line-height: 22px;
}

%caption {
  font-size: 14px;
  line-height: 19px;
}

%caption-2 {
  font-size: 14px;
  line-height: 20px;
}

%annotation {
  font-size: 12px;
  line-height: 140%;
}

//Media breakpoints
$tablet-width: 768px;
$min-tablet-width: 500px;
$mobile-width: 320px;
$desktop-width-breakpoint: 1000px;
$desktop-width: 1440px;
$max-desktop-width: 2550px;

@mixin desktop-breakpoint {
  @media (min-width: #{$tablet-width}) and (max-width: $desktop-width-breakpoint) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$min-tablet-width}) and (max-width: $tablet-width) {
    @content;
  }
}

@mixin mobile {
  @media (min-width: #{$mobile-width}) and (max-width: #{$min-tablet-width - 1}) {
    @content;
  }
}


@mixin non-desktop{
  @media (min-width: #{$mobile-width}) and (max-width: #{$tablet-width}) {
    @content;
  }
}
