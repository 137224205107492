@import './src/styles';

.sms-code-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  .alert-message {
    margin-bottom: 20px;
    justify-content: center;
  }

  .resend-wrapper {
    margin-top: 20px;
    button {
      margin-left: 10px;
    }
  }
}