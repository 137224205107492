@import './src/styles';

nav.navbar {

  position: sticky;
  top: 120px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  width: 243px;
  transition: all 0.2s ease-in-out;
}

.navigation-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
}

nav.navbar.collapsed {
  transition: all 0.2s ease-in-out;
  width: 95px;
}

@include desktop-breakpoint {
  nav.navbar {
    width: 222px;
  }
}

@include non-desktop {
  nav.navbar {
    width: 209px;
  }
}

@include mobile {
  nav.navbar.collapsed {
    overflow: auto;
    display: flex;
    justify-content: flex-start;
    position: absolute;
    padding: 20px;
    top: 79px;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    background-color: $white;
    z-index: 5;

    .user-panel {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 30px;
    }


  }

  .navigation-wrapper {
    .account-dropdown-container {
      order: -1;
    }
  }

  nav.navbar {
    display: none;
  }
}