@import './src/styles';

.no-outlet {
  .wrapper {
    display: flex;
    gap: 29px;
  }

  &__left-side {
    padding-top: 10px;
  }

  &__right-side {
    max-width: 335px;
  }

  &__label {
    margin-bottom: 20px;
    @extend %display-h1;
    color: $header;
  }

  &__description {
    margin-bottom: 40px;
    @extend %body;
    color: $dark-neutral;
  }

  &__button {
    min-width: 185px;
  }
}

@include mobile {
  .no-outlet {
    .wrapper {
      flex-direction: column;
      align-items: center;
    }
  }
}